import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { service } from "@ember/service";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import templateOnly from "@ember/component/template-only";
import esc from "../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export default class DBreadcrumbsItem extends Component {
  static #_ = (() => dt7948.g(this.prototype, "breadcrumbs", [service]))();
  #breadcrumbs = (() => (dt7948.i(this, "breadcrumbs"), void 0))();
  static #_2 = (() => dt7948.g(this.prototype, "router", [service]))();
  #router = (() => (dt7948.i(this, "router"), void 0))();
  constructor() {
    super(...arguments);
    this.breadcrumbs.items.add(this);
  }
  willDestroy() {
    super.willDestroy(...arguments);
    this.breadcrumbs.items.delete(this);
  }
  get url() {
    if (this.args.model) {
      return this.router.urlFor(this.args.route, this.args.model);
    } else {
      return this.router.urlFor(this.args.route);
    }
  }
  get templateForContainer() {
    // Those are evaluated in a different context than the `@linkClass`
    const {
      label
    } = this.args;
    const url = this.url;
    return setComponentTemplate(createTemplateFactory(
    /*
      
          <li ...attributes>
            <a href={{url}} class={{@linkClass}}>
              {{label}}
            </a>
          </li>
        
    */
    {
      "id": "p9PhtpY/",
      "block": "[[[1,\"\\n      \"],[11,\"li\"],[17,1],[12],[1,\"\\n        \"],[10,3],[15,6,[32,0]],[15,0,[30,2]],[12],[1,\"\\n          \"],[1,[32,1]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[\"&attrs\",\"@linkClass\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/d-breadcrumbs-item.js",
      "scope": () => [url, label],
      "isStrictMode": true
    }), templateOnly());
  }
  static #_3 = (() => dt7948.n(this.prototype, "templateForContainer", [cached]))();
}