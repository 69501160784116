import { action } from "@ember/object";
import { service } from "@ember/service";
import RestrictedUserRoute from "discourse/routes/restricted-user";
export default class PreferencesProfile extends RestrictedUserRoute {
  static #_ = (() => dt7948.g(this.prototype, "currentUser", [service]))();
  #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
  setupController(controller, model) {
    controller.set("model", model);
  }
  willTransition(transition) {
    super.willTransition(...arguments);
    if (this.currentUser?.needs_required_fields_check && !transition?.to.name.startsWith("admin")) {
      transition.abort();
      return false;
    }
    return true;
  }
  static #_2 = (() => dt7948.n(this.prototype, "willTransition", [action]))();
}