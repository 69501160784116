import Component from "@glimmer/component";
import "@glimmer/tracking";
let cached = esc(require('@glimmer/tracking')).cached;
import { getOwner, setOwner } from "@ember/owner";
import { service } from "@ember/service";
import ApiSection from "./api-section";
import PanelHeader from "./panel-header";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
import esc from "../../../../../../../../../node_modules/@embroider/macros/src/addon/es-compat2";
export default class SidebarApiSections extends Component {
  static #_ = (() => dt7948.g(this.prototype, "sidebarState", [service]))();
  #sidebarState = (() => (dt7948.i(this, "sidebarState"), void 0))();
  get sections() {
    let sectionConfigs1;
    if (this.sidebarState.combinedMode) {
      sectionConfigs1 = this.sidebarState.panels.filter(panel1 => !panel1.hidden).flatMap(panel1 => panel1.sections);
    } else {
      sectionConfigs1 = this.sidebarState.currentPanel.sections;
    }
    return sectionConfigs1.map(Section1 => {
      const SidebarSection1 = prepareSidebarSectionClass(Section1);
      const sectionInstance1 = new SidebarSection1({
        filterable: !this.sidebarState.combinedMode && this.sidebarState.currentPanel.filterable,
        sidebarState: this.sidebarState
      });
      setOwner(sectionInstance1, getOwner(this));
      return sectionInstance1;
    });
  }
  get filteredSections() {
    return this.sections.filter(section1 => section1.filtered);
  }
  static #_2 = (() => setComponentTemplate(createTemplateFactory(
  /*
    
      <PanelHeader @sections={{this.filteredSections}} />
  
      {{#each this.filteredSections as |section|}}
        <ApiSection @section={{section}} @collapsable={{@collapsable}} />
      {{/each}}
    
  */
  {
    "id": "HnyhbLET",
    "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@sections\"],[[30,0,[\"filteredSections\"]]]],null],[1,\"\\n\\n\"],[42,[28,[31,1],[[28,[31,1],[[30,0,[\"filteredSections\"]]],null]],null],null,[[[1,\"      \"],[8,[32,1],null,[[\"@section\",\"@collapsable\"],[[30,1],[30,2]]],null],[1,\"\\n\"]],[1]],null],[1,\"  \"]],[\"section\",\"@collapsable\"],false,[\"each\",\"-track-array\"]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/sidebar/api-sections.js",
    "scope": () => [PanelHeader, ApiSection],
    "isStrictMode": true
  }), this))();
}
// extends the class provided for the section to add functionality we don't want to be overridable when defining custom
// sections using the plugin API, like for example the filtering capabilities
function prepareSidebarSectionClass(Section1) {
  return class extends Section1 {
    constructor(_ref) {
      let {
        filterable: filterable1,
        sidebarState: sidebarState1
      } = _ref;
      super();
      this.filterable = filterable1;
      this.sidebarState = sidebarState1;
    }
    get filteredLinks() {
      if (!this.filterable || !this.sidebarState.filter) {
        return this.links;
      }
      if (this.text.toLowerCase().match(this.sidebarState.sanitizedFilter)) {
        return this.links;
      }
      return this.links.filter(link1 => {
        return link1.text.toString().toLowerCase().match(this.sidebarState.sanitizedFilter) || link1.keywords.navigation.some(keyword1 => keyword1.match(this.sidebarState.sanitizedFilter));
      });
    }
    static #_ = (() => dt7948.n(this.prototype, "filteredLinks", [cached]))();
    get filtered() {
      return !this.filterable || this.filteredLinks?.length > 0;
    }
  };
}